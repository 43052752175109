<template>
  <div class="bg-color px-3">
    <v-container class="v-container-fluid" fluid>
      <!-- Primer renglón -->
      <v-row class="align-center">
        <!-- Primera columna: Logo -->
        <v-col cols="12" md="4" sm="12" xs="12">
          <div class="content-image-logo">
            <div class="content-logo">
              <img src="@/assets/logos/Logotipo_Negativo.png" alt="Logotipo Negativo" />
            </div>
          </div>
        </v-col>

        <!-- Segunda columna: Texto -->
        <v-col cols="12" md="4" sm="12" xs="12" class="d-flex align-center justify-center">
          <p class="txt-description mon-regular text-centered">
            {{ texts.FooterLanding.central }}
          </p>
        </v-col>

        <!-- Tercera columna: Redes -->
        <v-col cols="12" md="4" sm="12" xs="12" class="d-flex align-center justify-center">
          <div class="content-social-icons">
            <a style="text-decoration: none;">
              <img src="@/assets/logos/facebook-logo.svg" alt="Facebook" />
              <span class="social-text">Facebook</span>
            </a>
            <a @click="redirectInstagram" style="text-decoration: none;">
              <img src="@/assets/logos/instagram-logo.svg" alt="Instagram" />
              <span class="social-text">Instagram</span>
            </a>
          </div>
        </v-col>
      </v-row>

      <!-- Separador -->
      <v-row>
        <v-col cols="12">
          <hr class="separator-line" />
        </v-col>
      </v-row>

      <!-- Segundo renglón -->
      <v-row class="align-center">
        <!-- Primera columna: Términos y condiciones -->
        <v-col cols="12" md="4" sm="12" xs="12" class="text-center">
          <p class="txt-link" style="cursor: pointer;">
            {{ texts.FooterLanding.TC }}
          </p>
        </v-col>

        <!-- Segunda columna: Derechos reservados -->
        <v-col cols="12" md="4" sm="12" xs="12" class="text-center">
          <p class="txt-description mon-regular text-centered">
            {{ texts.FooterLanding.central2 }}
          </p>
        </v-col>

        <!-- Tercera columna: Creado por PROPLAT -->
        <v-col cols="12" md="4" sm="12" xs="12" class="text-center">
          <p class="txt-description mon-regular text-centered">
            {{ texts.FooterLanding.Created }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      texts: "",
    };
  },
  beforeMount: function () {
    this.texts = FILE.landing[this.selectLanguage];
  },
  methods: {
    scrollToSection(sectionId) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        const offset = 100;
        const targetPosition = targetElement.offsetTop - offset;
        window.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      }
    },
    redirectLink: function () {
      let link =
        "https://proplat-public-images.s3.amazonaws.com/PROPLAT-TERMINOS_Y_CONDICIONES.pdf";
      window.open(link, "blank");
    },
    redirectFacebook: function () {
      window.open(
        "https://www.facebook.com/people/Proplat-Proplat/100073376927733/",
        "_blank"
      );
    },
    redirectInstagram: function () {
      window.open("https://www.instagram.com/fresherpay/", "_blank");
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.landing[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-color {
  width: 100%;
  background: #121a39;
  opacity: 1;
  padding-top: 25px;
  padding-bottom: 25px;
}

.content-logo img {
  width: 15vw;
  height: auto;
  max-width: 20vw; 
}

.txt-description {
  text-align: center;
  font-weight: 800;
  color: #bfd7ed;
  font-size: 14px;
  margin-bottom: 10px;
}

.txt-link {
  text-align: center;
  color: #bfd7ed;
  font-size: 14px;
  margin-bottom: 0;
  cursor: pointer;
  text-decoration: underline;
}

.separator-line {
  border: 1px solid #bebebe;
}

.social-text {
  font-size: 14px;
  color: #61a3d9;
  font-weight: 600;
}

.content-social-icons {
  display: flex;
  flex-direction: row; /* Coloca los íconos en fila */
  align-items: center;
  justify-content: space-between; /* Espacio entre los íconos */
}

.content-social-icons a {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.content-social-icons a img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

@media (max-width: 600px) {
  .content-social-icons {
    justify-content: center;
  }
}
</style>